<template>
  <div>
    <div class="contentsWidth mx-auto mt-4 mb-3">
      <div class="">
        <p class="title mb-0">選考評価担当割当 - {{apptype.year}}年度 {{apptype.name}}</p>
        <hr class="title">
      </div>
    </div>

    <div
      class="contentsWidth mx-auto my-3 notice-wrap">
      <p
        v-if="isOver"
        class="mb-0">この助成種別は選考評価が終了しているため、割当の変更はできません。
      </p>
      <p
        v-for="(msg, index) in errMsgs" :key="index"
        class="mb-0">{{msg}}
      </p>
    </div>

    <div class="contentsWidth mx-auto flex mb-3">
      <section class="left-box">
        <div class="stage-wrap">
          選考段階
          <b-form-select
            @change="loadAssignAppList"
            :options="stageList"
            v-model="form.stage">
            <template v-slot:first>
              <option :value="null">-- 選択してください。 --</option>
            </template>
          </b-form-select>
        </div>
        <div class="grader-wrap">
          選考評価担当者
          <b-form-select
            @change="loadAssignAppList"
            :options="markerList"
            v-model="form.marker">
            <template v-slot:first>
              <option :value="null">-- 選択してください。 --</option>
            </template>
          </b-form-select>
        </div>
        <div class="mt-1">
          <div>他の担当者の割当をコピーする</div>
          <div>
            <div class="inlineBlock">
              <b-form-select
                :disabled="!form.stage"
                :options="copyMakerSelect"
                v-model="copyAssignTarget">
                <template v-slot:first>
                  <option :value="null">-- 選択してください。 --</option>
                </template>
              </b-form-select>
            </div>
            <div class="inlineBlock ml-3">
              <b-button
                :disabled="!copyAssignTarget || !form.stage || !form.marker"
                @click="copyAssign"
                class="addBtn bold"
                >設定</b-button>
            </div>
          </div>
        </div>
        <perfect-scrollbar
          id="selected-scroll-wrap"
          class="w100 mt-4 p-3 main-box left">
          <vue-draggable
            id="selected-wrap"
            :move="canMove"
            v-bind="dragOptions"
            :list="selectedAppIdList">
              <div
                v-for="(selectedAppData, index) in selectedAppIdList" :key="index"
                @click.shift="checkShift(selectedAppData, 'left')"
                @click.exact="checkApp(selectedAppData)"
                :class="[
                  'item',
                  selectedAppData.isGroup ? (groupData[selectedAppData.id] && groupData[selectedAppData.id].check ? 'checked' : '') : (appData[selectedAppData.id] && appData[selectedAppData.id].check ? 'checked' : ''),
                  selectedAppData.isGroup ? (groupData[selectedAppData.id] && groupData[selectedAppData.id].shift ? 'shift' : '') : (appData[selectedAppData.id] && appData[selectedAppData.id].shift ? 'shift' : ''),
                ]">
                  <span v-if="selectedAppData.isGroup">
                    グループ{{groupData[selectedAppData.id].group}}（{{groupData[selectedAppData.id].count}}人）
                    | 割当{{leftCount(selectedAppData, groupData[selectedAppData.id].assign_count)}}人
                  </span>
                  <span v-else>
                    {{appData[selectedAppData.id].name}}
                    | 割当{{leftCount(selectedAppData, appData[selectedAppData.id].count)}}人
                    | {{appData[selectedAppData.id].division}}
                    | {{appData[selectedAppData.id].key_word}}
                  </span>
              </div>
          </vue-draggable>
        </perfect-scrollbar>

      </section>
      <section class="middle-box">
        <div class="arrow-wrap">
          <img
            @click="goRight"
            class="arrow-right"
            src="@/assets/svg/arrow-right.svg">
          <img
            v-show="canGoRight && !setNoTarget && !isOver"
            @click="goRight"
            class="arrow-right"
            src="@/assets/svg/arrow-right-blue.svg">
          <img
            @click="goLeft"
            class="arrow-left"
            src="@/assets/svg/arrow-left.svg">
          <img
            v-show="canGoLeft && !setNoTarget && !isOver"
            @click="goLeft"
            class="arrow-left"
            src="@/assets/svg/arrow-left-blue.svg">
        </div>
      </section>
      <section class="right-box">
        <div class="">
          申請者氏名
          <b-form-input
            class="search-input"
            @input="initCheckShift(); initGroupCheckShift();"
            v-model="filter.name"
            placeholder="入力してください"/>
        </div>
        <div class="assign_number-wrap mt-1">
          <p class="mb-0">割当人数</p>
          <b-form-input
            class="search-input assign_number"
            @input="initCheckShift; initGroupCheckShift();"
            v-model="filter.assign_min"
            placeholder="入力してください"/>
            ~
          <b-form-input
            class="search-input assign_number"
            @input="initCheckShift; initGroupCheckShift();"
            v-model="filter.assign_max"
            placeholder="入力してください"/>
        </div>
        <div class="mt-1">
          大区分
          <b-form-select
            class="search-input"
            @input="initCheckShift; initGroupCheckShift();"
            v-model="filter.division"
            :options="divisionOptions">
            <template v-slot:first>
              <option :value="''">-- 未選択 --</option>
            </template>
          </b-form-select>
        </div>
        <div class="mt-1">
          キーワード
          <b-form-input
            class="search-input"
            @input="initCheckShift; initGroupCheckShift();"
            v-model="filter.key_word"
            placeholder="入力してください"/>
        </div>

        <perfect-scrollbar
          class="w100 mt-4 p-3 main-box right">
          <vue-draggable
            id="selectable-wrap"
            :move="canMove"
            v-bind="dragOptions"
            :list="filterdSelectebleAppIdList">
              <div
                v-for="(filterdSelectebleAppData, index) in filterdSelectebleAppIdList" :key="index"
                @click.shift="checkShift(filterdSelectebleAppData, 'right')"
                @click.exact="checkApp(filterdSelectebleAppData)"
                :class="[
                  'item',
                  filterdSelectebleAppData.isGroup ? (groupData[filterdSelectebleAppData.id] && groupData[filterdSelectebleAppData.id].check ? 'checked' : '') : (appData[filterdSelectebleAppData.id] && appData[filterdSelectebleAppData.id].check ? 'checked' : ''),
                  filterdSelectebleAppData.isGroup ? (groupData[filterdSelectebleAppData.id] && groupData[filterdSelectebleAppData.id].shift ? 'shift' : '') : (appData[filterdSelectebleAppData.id] && appData[filterdSelectebleAppData.id].shift ? 'shift' : ''),
                ]">
                  <span v-if="filterdSelectebleAppData.isGroup">
                    グループ{{groupData[filterdSelectebleAppData.id].group}}（{{groupData[filterdSelectebleAppData.id].count}}人）
                    | 割当{{rightCount(filterdSelectebleAppData, groupData[filterdSelectebleAppData.id].assign_count)}}人
                  </span>
                  <span v-else>
                    {{appData[filterdSelectebleAppData.id].name}}
                    | 割当{{rightCount(filterdSelectebleAppData, appData[filterdSelectebleAppData.id].count)}}人
                    | {{appData[filterdSelectebleAppData.id].division}}
                    | {{appData[filterdSelectebleAppData.id].key_word}}
                  </span>
              </div>
          </vue-draggable>
        </perfect-scrollbar>
      </section>
    </div>

    <div class="mt-5 mb-3 flex flexCenter contentsWidth mx-auto">
      <b-button
        class="btn btn-lg bold"
        to="/cms/applications/assign/list">一覧に戻る</b-button>
      <b-button
        @click="save"
        :disabled="setNoTarget || isOver"
        variant="primary"
        class="btn btn-lg bold ml-2">登録</b-button>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import moment from 'moment';
import api from '@/modules/api';
import CONST_OPTIONS from '@/constants/options';


export default {
  name: 'cmsApplicationAssignEdit',
  components: {
    'vue-draggable': draggable,
  },
  data() {
    return {
      apptype: {},
      stageData: {},
      markerList: [],
      stageList: [],
      selectedAppIdList: [],
      selectedGroupIdList: [],
      checkedAppIdList: [],
      checkedGroupIdList: [],
      // shiftやaltでの複数選択機能に使う、始点と終点でappIdを最大２個入れておく配列
      checkShiftAppIds: [],
      checkShiftGroupIds: [],
      witchShift: null,
      form: {
        stage: null,
        marker: null,
      },
      filter: {
        name: '',
        assign_min: '',
        assign_max: '',
        division: '',
        key_word: '',
      },
      appData: {},
      dbAppIdList: [],
      dbMarkerList: [],
      dragOptions: {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      },
      errMsgs: [],
      copyAssignTarget: null,
      groupData: {},
      dbGroupIdList: [],
    };
  },
  computed: {
    selectableAppIdList() {
      const selectableAppIdList = [];
      // グループ関連を準備
      Object.keys(this.groupData).forEach((key) => {
        if (this.selectedAppIdList.findIndex((element) => { return element.id === this.groupData[key].group_id; }) === -1) {
          selectableAppIdList.push({ id: this.groupData[key].group_id, isGroup: true });
        }
      });
      // 個別関連を準備
      Object.keys(this.appData).forEach((key) => {
        if (this.selectedAppIdList.findIndex((element) => { return element.id === this.appData[key].id; }) === -1) {
          selectableAppIdList.push({ id: this.appData[key].id, isGroup: false });
        }
      });
      return selectableAppIdList;
    },
    // todo:isDeleted
    selectableGroupAppIdList() {
      const selectableGroupAppIdList = [];
      Object.keys(this.groupData).forEach((key) => {
        if (this.selectedGroupIdList.indexOf(this.groupData[key].group_id) === -1) {
          selectableGroupAppIdList.push(this.groupData[key].group_id);
        }
      });
      return selectableGroupAppIdList;
    },
    filterdSelectebleAppIdList() {
      let filterdSelectebleAppIdList = [];
      filterdSelectebleAppIdList = this.selectableAppIdList.filter((data) => {
        const appId = data.id;
        if (data.isGroup) {
          if (this.filter.name !== '' || this.filter.division !== '' || this.filter.key_word !== '') {
            return false;
          }
          const okMin = this.filter.assign_min === '' || this.filter.assign_min <= this.groupData[appId].assign_count;
          const okMax = this.filter.assign_max === '' || this.filter.assign_max >= this.groupData[appId].assign_count;
          return okMin && okMax;
        }
        const okName = this.appData[appId].name.includes(this.filter.name);
        const okDiv = this.appData[appId].division.includes(this.filter.division);
        const okKeyWord = this.appData[appId].key_word.includes(this.filter.key_word);
        const okMin = this.filter.assign_min === '' || this.filter.assign_min <= this.appData[appId].count;
        const okMax = this.filter.assign_max === '' || this.filter.assign_max >= this.appData[appId].count;
        let isNoneGroup = true;
        if (this.stageData.stage === 2 && this.apptype.isUseGroup === 1) {
          isNoneGroup = this.appData[appId].group_id === null;
        }
        return okName && okDiv && okKeyWord && okMin && okMax && isNoneGroup;
      });
      return filterdSelectebleAppIdList;
    },
    filterdSelectebleGroupAppIdList() {
      return this.selectableGroupAppIdList;
    },
    setNoTarget() {
      return this.form.stage === null || this.form.marker === null;
    },
    canGoLeft() {
      let result = false;
      this.selectableAppIdList.forEach((appData) => {
        const { isGroup } = appData;
        const appId = appData.id;
        if (isGroup && this.groupData[appId] && this.groupData[appId].check) {
          result = true;
        }
        if (!isGroup && this.appData[appId] && this.appData[appId].check) {
          result = true;
        }
      });
      return result;
    },
    canGoRight() {
      let result = false;
      this.selectedAppIdList.forEach((appData) => {
        const { isGroup } = appData;
        const appId = appData.id;
        if (isGroup && this.groupData[appId] && this.groupData[appId].check) {
          result = true;
        }
        if (!isGroup && this.appData[appId] && this.appData[appId].check) {
          result = true;
        }
      });
      return result;
    },
    // DBに登録されていない申請は、左の選択状態にした時にcountを１つ多く表示する
    leftCount() {
      return (selectedAppData, count) => {
        let calcCount = count;
        const { isGroup } = selectedAppData;
        const selectedAppId = selectedAppData.id;
        if (isGroup && this.dbGroupIdList.indexOf(selectedAppId) === -1) {
          calcCount += 1;
        } else if (!isGroup && this.dbAppIdList.indexOf(selectedAppId) === -1) {
          calcCount += 1;
        }
        return calcCount;
      };
    },
    // DBに登録されている申請は、右の未選択状態にした時にcountを１つ少なく表示する
    rightCount() {
      return (selectableAppData, count) => {
        let calcCount = count;
        const { isGroup } = selectableAppData;
        const selectableAppId = selectableAppData.id;
        if (isGroup && this.dbGroupIdList.indexOf(selectableAppId) !== -1) {
          calcCount -= 1;
        } else if (!isGroup && this.dbAppIdList.indexOf(selectableAppId) !== -1) {
          calcCount -= 1;
        }
        return calcCount;
      };
    },
    isOver() {
      const now = moment();
      const endTime = moment(this.apptype.result_announcement_datetime);
      return endTime !== null && now.isSameOrAfter(endTime);
    },
    divisionOptions() {
      return CONST_OPTIONS.DIVISIONS;
    },
    selectedStage() {
      const selectedStage = this.stageList.find((stage) => {
        return stage.value === this.form.stage;
      });
      return selectedStage;
    },
    copyMakerSelect() {
      const markerList = [];
      this.dbMarkerList.forEach((marker) => {
        const data = {
          value: marker.id,
          text: `${marker.name}`,
        };
        markerList.push(data);
      });
      return markerList;
    },
  },
  methods: {
    async fetchInitDate(apptypeId) {
      const param = {
        apptypeId,
      };
      const response = await api.send('/api/cms/applications/assign/edit', param)
        .catch((err) => {
          console.error(err);
        });
      if (!response) {
        await this.alert('データを取得できませんでした。');
        this.$router.push({ path: 'cms/applications/assign/list' });
        return;
      }
      this.apptype = response.data.apptype;
      this.markerList = this.makeMakerSelect(response.data.markerList);
      this.stageList = this.makeStageSelect(response.data.stageList);
    },
    async loadAssignAppList() {
      if (this.form.stage === null || this.form.marker === null) {
        this.appData = {};
        this.groupData = {};
        this.selectedAppIdList = [];
        this.selectedGroupIdList = [];
        this.initCheckState();
        this.initCheckShift();
        this.initGroupCheckShift();
        return;
      }
      this.errMsgs = [];
      this.$store.dispatch('page/onWaiting');
      const param = {
        apptypeId: this.apptype.id,
        stageId: this.form.stage,
        markerId: this.form.marker,
      };
      const response = await api.send('/api/cms/applications/assign/assignAppList', param)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('データを取得できませんでした。');
        return;
      }
      this.initCheckState();
      this.initCheckShift();
      this.initGroupCheckShift();
      this.appData = response.data.appData;
      this.groupData = response.data.groupList;
      this.stageData = response.data.stageData;
      this.selectedAppIdList = [];
      const { groupAssignList } = response.data;
      groupAssignList.forEach((appId) => {
        const data = this.groupData[appId];
        if (data) {
          this.selectedAppIdList.push({ id: data.group_id, isGroup: true });
        }
      });
      const { assignAppList } = response.data;
      assignAppList.forEach((appId) => {
        const data = this.appData[appId];
        if (data && (data.group_id === null || this.stageData.stage !== 2)) {
          this.selectedAppIdList.push({ id: data.id, isGroup: false });
        }
      });
      this.markerList = this.makeMakerSelect(response.data.markerList);
      this.dbMarkerList = response.data.markerList;
      this.dbAppIdList = response.data.assignAppList.slice();
      this.dbGroupIdList = response.data.groupAssignList.slice();
    },
    async save() {
      this.errMsgs = [];
      this.$store.dispatch('page/onWaiting');
      const param = {
        stageId: this.form.stage,
        markerId: this.form.marker,
        apptypeId: this.apptype.id,
        selectedAppIdList: this.selectedAppIdList,
      };
      const response = await api.send('/api/cms/applications/assign/save', param)
        .catch(async (err) => {
          // 登録しましたのアラートが背景ありだと妙なスクロールが起きるので、Offwaitingを別で設定
          this.$store.dispatch('page/offWaiting');
          await this.alert('登録に失敗しました。');
          this.errMsgs = err.response.data.messages;
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        return;
      }
      await this.alert('登録しました。');
      await this.loadAssignAppList();
    },
    // falseを返すとvue-draggableのdrag操作を元に戻す
    canMove() {
      return !this.setNoTarget && !this.isOver;
    },
    makeMakerSelect(dbMarkerList) {
      const markerList = [];
      dbMarkerList.forEach((marker) => {
        const data = {
          value: marker.id,
          text: `${marker.name} | ${this.apptype.year}度割当数：${marker.count}件`,
        };
        markerList.push(data);
      });
      return markerList;
    },
    makeStageSelect(dbStageList) {
      const stageList = [];
      dbStageList.forEach((stage) => {
        const data = {
          value: stage.id,
          text: `${stage.stage}次`,
        };
        stageList.push(data);
      });
      return stageList;
    },
    checkApp(appData) {
      const { isGroup } = appData;
      const appId = appData.id;
      if (isGroup) {
        this.initGroupCheckShift();
        const index = this.checkedGroupIdList.indexOf(appId);
        if (index === -1) {
          this.checkedGroupIdList.push(appId);
          this.$set(this.groupData[appId], 'check', true);
        } else {
          this.checkedGroupIdList.splice(index, 1);
          this.$set(this.groupData[appId], 'check', false);
        }
      } else {
        this.initCheckShift();
        const index = this.checkedAppIdList.indexOf(appId);
        if (index === -1) {
          this.checkedAppIdList.push(appId);
          this.$set(this.appData[appId], 'check', true);
        } else {
          this.checkedAppIdList.splice(index, 1);
          this.$set(this.appData[appId], 'check', false);
        }
      }
    },
    checkShift(appData, which) {
      const { isGroup } = appData;
      const appId = appData.id;
      // const index = this.checkedAppIdList.indexOf(appId);
      // 左右どちらを操作するか保存。前回と異なれば選択状態初期化

      if (this.witchShift !== which) {
        if (isGroup) {
          this.initGroupCheckShift();
        } else {
          this.initCheckShift();
        }
      }
      this.witchShift = which;
      let targetList = [];
      if (which === 'left') {
        targetList = this.selectedAppIdList;
      } else {
        targetList = this.filterdSelectebleAppIdList;
      }
      // すでに選択済みの場合は解除して終了
      if (isGroup) {
        const checkIndex = this.checkShiftGroupIds.indexOf(appId);
        if (checkIndex !== -1) {
          this.$set(this.groupData[appId], 'shift', false);
          this.checkShiftGroupIds.splice(checkIndex, 1);
          return;
        }
        const count = this.checkShiftGroupIds.length;
        // すでに2つ以上選択されていたら1つだけにする（始点のみにする）
        if (count >= 2) {
          this.checkShiftGroupIds.forEach((removeAppId, index) => {
            if (index > 0) {
              this.$set(this.groupData[removeAppId], 'shift', false);
            }
          });
          this.checkShiftGroupIds = [this.checkShiftGroupIds[0]];
        }
        // クリックされたものをshift選択状態にする
        this.checkShiftGroupIds.push(appId);
        this.$set(this.groupData[appId], 'shift', true);
        // 2つ揃っていない場合は処理終了
        if (this.checkShiftGroupIds.length < 2) {
          return;
        }
        const val1Index = targetList.findIndex((element) => { return element.id === this.checkShiftGroupIds[0]; });
        const val2Index = targetList.findIndex((element) => { return element.id === this.checkShiftGroupIds[1]; });
        if (val1Index === -1 || val2Index === -1) {
          console.error('error');
          this.checkShiftGroupIds = [];
          return;
        }
        // keyのインデックスを小さい順の配列にする。
        const keyIndexList = val1Index < val2Index ? [val1Index, val2Index] : [val2Index, val1Index];
        // 小さいインデックスから大きいインデックスまでを選択状態にし、ついでにshift選択状態を解除する
        for (let i = keyIndexList[0]; i <= keyIndexList[1]; i += 1) {
          const targetId = targetList[i].id;
          this.$set(this.groupData[targetId], 'check', true);
          this.$set(this.groupData[targetId], 'shift', false);
          const index = this.checkedGroupIdList.indexOf(targetId);
          // 通常選択データ上も選択状態にする
          if (index === -1) {
            this.checkedGroupIdList.push(targetId);
          }
        }
        // shift選択のデータ側も初期化
        this.checkShiftGroupIds = [];
      } else {
        const checkIndex = this.checkShiftAppIds.indexOf(appId);
        if (checkIndex !== -1) {
          this.$set(this.appData[appId], 'shift', false);
          this.checkShiftAppIds.splice(checkIndex, 1);
          return;
        }
        const count = this.checkShiftAppIds.length;
        // すでに2つ以上選択されていたら1つだけにする（始点のみにする）
        if (count >= 2) {
          this.checkShiftAppIds.forEach((removeAppId, index) => {
            if (index > 0) {
              this.$set(this.appData[removeAppId], 'shift', false);
            }
          });
          this.checkShiftAppIds = [this.checkShiftAppIds[0]];
        }
        // クリックされたものをshift選択状態にする
        this.checkShiftAppIds.push(appId);
        this.$set(this.appData[appId], 'shift', true);
        // 2つ揃っていない場合は処理終了
        if (this.checkShiftAppIds.length < 2) {
          return;
        }
        const val1Index = targetList.findIndex((element) => { return element.id === this.checkShiftAppIds[0]; });
        const val2Index = targetList.findIndex((element) => { return element.id === this.checkShiftAppIds[1]; });
        if (val1Index === -1 || val2Index === -1) {
          console.error('error');
          this.checkShiftAppIds = [];
          return;
        }
        // keyのインデックスを小さい順の配列にする。
        const keyIndexList = val1Index < val2Index ? [val1Index, val2Index] : [val2Index, val1Index];
        // 小さいインデックスから大きいインデックスまでを選択状態にし、ついでにshift選択状態を解除する
        for (let i = keyIndexList[0]; i <= keyIndexList[1]; i += 1) {
          const targetId = targetList[i].id;
          this.$set(this.appData[targetId], 'check', true);
          this.$set(this.appData[targetId], 'shift', false);
          const index = this.checkedAppIdList.indexOf(targetId);
          // 通常選択データ上も選択状態にする
          if (index === -1) {
            this.checkedAppIdList.push(targetId);
          }
        }
        // shift選択のデータ側も初期化
        this.checkShiftAppIds = [];
      }
    },
    goLeft() {
      if (this.setNoTarget || this.isOver || !this.canGoLeft) {
        return;
      }
      this.checkedGroupIdList.forEach((appId) => {
        const selectedIndex = this.selectedAppIdList.findIndex((element) => { return element.id === appId; });
        if (selectedIndex === -1) {
          const data = this.selectableAppIdList.find((element) => { return element.id === appId; });
          this.selectedAppIdList.push(data);
        }
        this.$set(this.groupData[appId], 'check', false);
      });
      this.checkedAppIdList.forEach((appId) => {
        const selectedIndex = this.selectedAppIdList.findIndex((element) => { return element.id === appId; });
        if (selectedIndex === -1) {
          const data = this.selectableAppIdList.find((element) => { return element.id === appId; });
          this.selectedAppIdList.push(data);
        }
        this.$set(this.appData[appId], 'check', false);
      });

      this.checkedAppIdList = [];
      this.checkedGroupIdList = [];
    },
    goRight() {
      if (this.setNoTarget || this.isOver || !this.canGoRight) {
        return;
      }
      this.checkedGroupIdList.forEach((appId) => {
        const selectedIndex = this.selectedAppIdList.findIndex((element) => { return element.id === appId; });
        if (selectedIndex !== -1) {
          this.selectedAppIdList.splice(selectedIndex, 1);
        }
        this.$set(this.groupData[appId], 'check', false);
      });
      this.checkedAppIdList.forEach((appId) => {
        const selectedIndex = this.selectedAppIdList.findIndex((element) => { return element.id === appId; });
        if (selectedIndex !== -1) {
          this.selectedAppIdList.splice(selectedIndex, 1);
        }
        this.$set(this.appData[appId], 'check', false);
      });

      this.checkedAppIdList = [];
      this.checkedGroupIdList = [];
    },
    // 通常の選択状態の初期化
    initCheckState() {
      Object.keys(this.appData).forEach((appId) => {
        this.$set(this.appData[appId], 'check', false);
      });
      this.checkedAppIdList = [];

      this.checkedGroupIdList.forEach((id) => {
        this.$set(this.groupData[id], 'check', false);
      });
      this.checkedGroupIdList = [];
    },
    // shift選択状態の初期化
    initCheckShift() {
      this.checkShiftAppIds.forEach((id) => {
        this.$set(this.appData[id], 'shift', false);
      });
      this.checkShiftAppIds = [];
    },
    initGroupCheckShift() {
      this.checkShiftGroupIds.forEach((id) => {
        this.$set(this.groupData[id], 'shift', false);
      });
      this.checkShiftGroupIds = [];
    },
    async copyAssign() {
      const msg = `${this.apptype.name}の${this.selectedStage.text}を選択した担当者と同じ内容に変更します。元のデータは上書きされますが、実行してよろしいですか？`;
      if (!await this.confirm(msg)) {
        return;
      }

      this.errMsgs = [];
      this.$store.dispatch('page/onWaiting');
      const param = {
        stageId: this.form.stage,
        targetMarkerId: this.copyAssignTarget,
      };
      const response = await api.send('/api/cms/applications/assign/copy', param)
        .catch(async (err) => {
          // 登録しましたのアラートが背景ありだと妙なスクロールが起きるので、Offwaitingを別で設定
          this.$store.dispatch('page/offWaiting');
          await this.alert('登録に失敗しました。');
          this.errMsgs = err.response.data.messages;
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        return;
      }
      this.selectedAppIdList = response.data.copyIdList;
    },
  },
  // ロード画面
  async created() {
    this.$store.dispatch('page/onLoading');
    const { apptypeId } = this.$route.params;
    await this.fetchInitDate(apptypeId);
    this.$store.dispatch('page/offLoading');
  },
};
</script>

<style scoped>
  select, input {
    height: 50px;
  }
  select.search-input, input.search-input {
    height: 45px;
  }
  .stage-wrap {
    width: 200px;
    display: inline-block;
    margin-right: 20px;
  }
  .grader-wrap {
    width: 400px;
    display: inline-block;
  }

  .left-box {
    width: 620px;
    height: 100%;
    border-width: 1px;
    border-color: #CCCCCC;
  }

  .middle-box {
    width: 60px;
    height: 100%;
  }
  .arrow-wrap {
    position: relative;
  }
  .arrow-left {
    position: absolute;
    top: 370px;
    left: 9px;
    cursor: pointer;
  }
  .arrow-right {
    position: absolute;
    top: 305px;
    left: 9px;
    cursor: pointer;
  }

  .arrow-left.disabled, .arrow-right.disabled {
    cursor: not-allowed;
  }


  .right-box {
    width: 400px;
    height: 100%;
  }

  .main-box {
    background-color: #fff;
    border:solid 1px #CCC;
  }

  .main-box.left {
    height: 541px;
  }

  .main-box.right {
    height: 404px;
  }

  .item {
    width: 100%;
    height: 40px;
    background-color: #E6E6E6;
    border-radius: 25px;
    padding: 9px 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 7px 0;
    cursor: pointer;
  }

  .item.checked {
    background-color: rgba(18, 40, 137, 0.2);
  }

  .item.shift {
    border: solid 1px rgba(18, 40, 137);
  }

  #selected-wrap {
    min-height: 100%;
  }
  #selectable-wrap {
    min-height: 100%;
  }

  #selected-scroll-wrap.disable {
    background-color: #F3F3F3;
    cursor: not-allowed;
  }

  .assign_number-wrap>input {
    display: inline-block;
  }

  .assign_number {
    width: 188px;
  }

  .notice-wrap {
    color: #D56E6E;
  }

  .assign-copy-wrap {
    width: 400px;
  }
</style>
